import React from 'react'
import compose from 'recompose/compose'
import * as mo from 'moment'
import {
  Paper,
  TextField,
  Button,
  LinearProgress,
  withStyles
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { TopBar, Snack, ModalCust, GraphDaily } from './Lib'
import { styleGuide } from './Style'
import __ from '../util'

class DashView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      start: mo()
        .utc()
        .startOf('day')
        .subtract(14, 'days'),
      end: mo()
        .utc()
        .endOf('day'),
      displayData: undefined
    }
    this.state = {
      ...this.reset
    }
    this.load = this.load.bind(this)
    this.handleGoBack = () => this.props.history.push('/dash')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'dash'))
    await this.load()
  }

  async load () {
    let statsDaily

    try {
      ;[statsDaily] = await Promise.all([
        this.cx.dash.loadStatsDaily(
          this.state.start.format('x'),
          this.state.end.format('x')
        )
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }

    this.setState({
      refresh: false,
      statsDaily
    })
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.statsDaily) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noInsta={!this.state.isCrawlAllowed}
            noStats
            iconLeft={<ArrowBack />}
            midTitle='Stats'
            onClickLeft={this.handleGoBack}
          />
          <Paper align='right'>
            <Button
              variant='contained'
              size='small'
              onClick={async evt => {
                await this.setState({
                  start: mo()
                    .utc()
                    .startOf('month'),
                  end: mo()
                    .utc()
                    .endOf('month')
                })
                await this.load()
              }}
            >
              This Month
            </Button>
            <Button
              variant='contained'
              size='small'
              onClick={async evt => {
                await this.setState({
                  start: mo()
                    .utc()
                    .startOf('month')
                    .subtract(1, 'month'),
                  end: mo()
                    .utc()
                    .subtract(1, 'month')
                    .endOf('month')
                })
                await this.load()
              }}
            >
              Last Month
            </Button>
            <Button
              variant='contained'
              size='small'
              onClick={async evt => {
                await this.setState({
                  start: mo()
                    .utc()
                    .startOf('month')
                    .subtract(2, 'month'),
                  end: mo()
                    .utc()
                    .subtract(1, 'month')
                    .endOf('month')
                })
                await this.load()
              }}
            >
              Last 2 months
            </Button>
            <Button
              variant='contained'
              size='small'
              onClick={async evt => {
                await this.setState({
                  start: mo()
                    .utc()
                    .startOf('month')
                    .subtract(3, 'month'),
                  end: mo()
                    .utc()
                    .subtract(1, 'month')
                    .endOf('month')
                })
                await this.load()
              }}
            >
              Last 3 months
            </Button>
            <TextField
              id='start'
              label='Start'
              type='date'
              defaultValue={this.state.start.format(__.cfg('tsYearFormat'))}
              InputLabelProps={{
                shrink: true
              }}
              onChange={evt => {
                const newStart = mo
                  .utc(evt.target.value, 'YYYY-MM-DD')
                  .startOf('day')
                if (newStart.isSameOrBefore(this.state.end, 'day')) {
                  this.setState({ start: newStart, refresh: true })
                } else {
                  evt.target.value = this.state.start.format(
                    __.cfg('tsYearFormat')
                  )
                  return false
                }
              }}
            />
            <TextField
              id='end'
              label='End'
              type='date'
              defaultValue={this.state.end.format(__.cfg('tsYearFormat'))}
              InputLabelProps={{
                shrink: true
              }}
              onChange={evt => {
                const newEnd = mo
                  .utc(evt.target.value, 'YYYY-MM-DD')
                  .startOf('day')
                if (newEnd.isSameOrAfter(this.state.start, 'day')) {
                  this.setState({ end: newEnd, refresh: true })
                } else {
                  evt.target.value = this.state.end.format(
                    __.cfg('tsYearFormat')
                  )
                  return false
                }
              }}
            />
            <Button
              variant='contained'
              color='primary'
              disabled={!this.state.refresh}
              onClick={async evt => {
                await this.load()
              }}
            >
              Refresh
            </Button>
          </Paper>
          <Paper>
            <div style={{ marginLeft: 40, marginRight: 40 }}>
              <GraphDaily data={this.state.statsDaily} />
            </div>
          </Paper>
        </div>
      )
    } else {
      return <LinearProgress />
    }
  }
}

export default compose(withStyles({}))(DashView)
