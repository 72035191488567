import { createMuiTheme } from '@material-ui/core/styles'
import common from '@material-ui/core/colors/common'
import blueGrey from '@material-ui/core/colors/blueGrey'

const styleGuide = {
  backgroundLight: '#F4F2F5',
  backgroundDark: '#575757',
  text: '#000',
  textSecondary: '#757575',
  spacertop: '80px'
}

const theme = createMuiTheme({
  palette: {
    palette: {
      primary: blueGrey,
      // {
      // light: '#5471dc',
      // main: styleGuide.backgroundDark,
      // dark: '#00207a',
      // contrastText: '#fff'
      // },
      secondary: {
        light: '#90f9cd',
        main: '#5cc69c',
        dark: '#22956e',
        contrastText: '#000'
      }
    },
    background: {
      default: styleGuide.backgroundLight,
      paper: common.white,
      light: styleGuide.backgroundLight
    },
    text: {
      primary: styleGuide.text,
      secondary: styleGuide.textSecondary
    }
  },
  typography: {
    useNextVariants: true,
    /* fontFamily: 'sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 400, */
    h2: {
      fontSize: 48,
      lineHeight: 1,
      fontWeight: 'bold'
    },
    h3: {
      fontSize: 32,
      lineHeight: 1.25
    },
    h4: {
      fontSize: 26,
      lineHeight: 1.5
    },
    h5: {
      fontSize: 24,
      lineHeight: 1.5
    },
    title: {
      fontSize: 24,
      lineHeight: 1
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '24px'
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.25
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.5
    },
    button: {
      fontSize: 16
    }
  }
})

theme.overrides = {
  MuiSnackbarContent: {
    root: {
      backgroundColor: styleGuide.textSecondary,
      color: common.black
    }
  },
  MuiSnackbar: {
    anchorOriginTopCenter: {
      [theme.breakpoints.up('md')]: {
        top: '50px'
      }
    }
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: styleGuide.backgroundDark,
      color: common.white
    }
  },
  MuiMobileStepper: {
    root: {
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    dot: {
      backgroundColor: theme.palette.primary[500]
    },
    dotActive: {
      backgroundColor: common.white
    }
  },
  MuiTab: {
    root: {
      fontWeight: 'bold',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px'
      }
    }
  },
  MuiTabScrollButton: {
    root: {
      color: styleGuide.textSecondary
    }
  },
  MUIDataTable: {
    responsiveScroll: {
      maxHeight: 700
    }
  },
  MUIDataTableBodyCell: {
    root: {
      padding: '0px 0px 0px 16px'
    }
  },
  MUIDataTableFilterList: {
    chip: {
      display: 'none'
    }
  }
}

const loginStyle = {
  paddingTop: styleGuide.spacertop,
  paddingBottom: theme.spacing(2),
  color: common.white
}

const paperStyle = {
  padding: theme.spacing(3)
}

const dividerStyle = {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4)
}

const noTxtDeco = {
  textDecoration: 'none'
}

const extLink = {
  color: styleGuide.text
}

const fullWidth = {
  width: '100%'
}

const gridWrapPaper = {
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.light
}

const gridItem = {
  marginBottom: theme.spacing(),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}

const fabProgress = {
  position: 'absolute',
  top: -6,
  left: -6,
  zIndex: 1
}

const gridSpacer = {
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}

const alertCard = {
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}

const loginButton = {
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing()
}

const lockIcon = {
  width: theme.spacing(2),
  height: theme.spacing(2)
}

const colorFormat = {
  display: 'block',
  height: '100%',
  textAlign: 'center'
}

export {
  styleGuide,
  theme,
  loginStyle,
  paperStyle,
  dividerStyle,
  noTxtDeco,
  extLink,
  fullWidth,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  fabProgress,
  alertCard,
  loginButton,
  lockIcon,
  colorFormat
}
