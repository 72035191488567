import React from 'react'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'
import Paper from '@material-ui/core/Paper'
import { ArrowBack } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import { TopBar, Snack, ModalCust } from './Lib'
import {
  styleGuide,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  paperStyle
} from './Style'
import __ from '../util'

class UserView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.state = {
      chipData: __.getJsonSto('core').countTotals
    }
    this.handleGoBack = () => this.props.history.push('/dash')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'user'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noUser
            noUpdate
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleGoBack}
            midTitle='User'
          />
          <Paper style={{ marginBottom: '60px' }}>
            <div className={this.props.classes.paperStyle}>
              <Typography variant='h3'>User Subids</Typography>
              {this.state.chipData.map((userSubid, i) => {
                let icon

                return (
                  <Chip
                    key={i}
                    icon={icon}
                    label={userSubid}
                    // onDelete={() => {}}
                    // className={this.props.classes.chip}
                  />
                )
              })}
            </div>
          </Paper>
          <Paper>
            <div className={this.props.classes.paperStyle}>
              <Typography variant='h3'>Actions</Typography>
              <Button
                variant='contained'
                onClick={() => {
                  this.cx.core.clear()
                  this.props.history.push('/login')
                }}
                style={{ marginTop: '60px' }}
              >
                Logout
              </Button>
            </div>
          </Paper>
        </div>
      )
    }
  }
}

export default compose(
  withStyles({
    gridWrapPaper,
    gridItem,
    gridSpacer,
    paperStyle
  }),
  withWidth()
)(UserView)
