import { ApiBase } from './Lib'
// import __ from '../util'

export default class User extends ApiBase {
  constructor (cx, _id, pld) {
    super('user', cx, _id || '3d01a5e1-37e0-43e8-9a1a-d54d93dc81f4')
    this._store = 'user'
    // this._apiGet = this._apiGet.bind(this)
    if (pld) this.setSto(pld)
  }

  /* async _apiGet () {
    const pld = await this.rqst({ url: 'user' })
    const user = await this.decrypt(pld.data)
    return user
  } */
}
