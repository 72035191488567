import React from 'react'
import compose from 'recompose/compose'
import { ArrowBack } from '@material-ui/icons'
import * as mo from 'moment'
import {
  TextField,
  Paper,
  Button,
  LinearProgress,
  withStyles
} from '@material-ui/core'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TopBar, Snack, ModalCust, TableColorFormaterMargin } from './Lib'
import { styleGuide, paperStyle, colorFormat } from './Style'
import __ from '../util'

class KeywordView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      start: mo()
        .utc()
        .startOf('day'),
      // .subtract(2, 'days'),
      end: mo()
        .utc()
        .endOf('day'),
      busy: false,
      keyword: []
    }
    this.state = {
      ...this.reset
    }
    this.load = this.load.bind(this)
    this.handleGoBack = () => this.props.history.push('/dash')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'keyword'))
    await this.load()
  }

  async load () {
    let keyword
    this.setState({
      busy: true
    })
    try {
      ;[keyword] = await Promise.all([
        this.cx.dash.loadKeyword(
          this.state.start.format('x'),
          this.state.end.format('x')
        )
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.setState({
      keyword,
      busy: false
    })
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (!this.state.busy) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noKeyword
            iconLeft={<ArrowBack />}
            midTitle='Keywords'
            onClickLeft={this.handleGoBack}
          />
          <Paper>
            <div style={{ textAlign: 'right' }}>
              <TextField
                id='start'
                label='Start'
                type='date'
                defaultValue={this.state.start.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newStart = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .startOf('day')
                  if (newStart.isSameOrBefore(this.state.end, 'day')) {
                    this.setState({ start: newStart, refresh: true })
                  } else {
                    evt.target.value = this.state.start.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <TextField
                id='end'
                label='End'
                type='date'
                defaultValue={this.state.end.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newEnd = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .startOf('day')
                  if (newEnd.isSameOrAfter(this.state.start, 'day')) {
                    this.setState({ end: newEnd, refresh: true })
                  } else {
                    evt.target.value = this.state.end.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <Button
                variant='contained'
                color='primary'
                disabled={!this.state.refresh}
                onClick={async evt => {
                  await this.load()
                }}
              >
                Refresh
              </Button>
            </div>
            <div className={this.props.classes.paperStyle}>
              {this.state.keyword.map((kw, i) => (
                <ExpansionPanel key={i}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography style={{ flex: 1 }}>{kw[0]}</Typography>
                    <Chip label={`Clicks ${kw[1]}`} />
                    <Chip label={`REV ${__.formatNumber(kw[2])} €`} />
                    <Chip
                      label={`RPC ${__.formatNumber(kw[3])} €`}
                      style={{ backgroundColor: __.colorValTable(kw[3]) }}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Paper style={{ width: '100%' }} elevation={0}>
                      <MUIDataTable
                        // title='Campaign Data'
                        data={kw[4]}
                        columns={[
                          {
                            name: 'Date',
                            options: {
                              display: false,
                              filterOptions: {
                                useDisplayedColumnsOnly: true,
                                useDisplayedRowsOnly: true
                              },
                              filterType: 'dropdown'
                            }
                          },
                          {
                            name: 'Keyword',
                            options: {
                              filterType: 'dropdown'
                            }
                          },
                          {
                            name: 'Clicks',
                            options: {
                              filterType: 'textField'
                            }
                          },
                          {
                            name: 'Revenue',
                            options: {
                              filterType: 'textField',
                              customBodyRender: value => {
                                return `${__.formatNumber(value)} €`
                              }
                            }
                          },
                          {
                            name: 'RPC',
                            options: {
                              filterType: 'textField',
                              customBodyRender: value => {
                                return (
                                  <TableColorFormaterMargin
                                    className={this.props.classes.colorFormat}
                                    colorValue={value}
                                    text={`${__.formatNumber(value)} €`}
                                  />
                                )
                              }
                            }
                          }
                        ]}
                        options={{
                          selectableRows: 'none',
                          print: false,
                          pagination: false,
                          search: false,
                          elevation: 0,
                          responsive: 'stacked',
                          selectableRowsHeader: false,
                          download: false,
                          viewColumns: false,
                          filter: false
                        }}
                      />
                    </Paper>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              {this.state.keyword.length === 0 && (
                <Typography variant='h6' align='center'>
                  Empty result for selected dates
                </Typography>
              )}
            </div>
          </Paper>
        </div>
      )
    } else {
      return <LinearProgress />
    }
  }
}

export default compose(
  withStyles({
    paperStyle,
    colorFormat
  })
)(KeywordView)
