import React from 'react'
import compose from 'recompose/compose'
import * as mo from 'moment'
import {
  Paper,
  TextField,
  Button,
  LinearProgress,
  withStyles
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import {
  TopBar,
  Snack,
  ModalCust,
  TableColorFormaterMargin,
  TableColorFormaterPercent
} from './Lib'
import Grid from '@material-ui/core/Grid'
import { styleGuide, colorFormat } from './Style'
import __ from '../util'

class DashView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      start: mo()
        .utc()
        .startOf('day')
        .subtract(2, 'days'),
      end: mo()
        .utc()
        .endOf('day'),
      displayData: undefined
    }
    this.state = {
      ...this.reset
    }
    this.load = this.load.bind(this)
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'dash'))
    await this.load()
  }

  async load () {
    let camps, costs

    try {
      ;[camps, costs] = await Promise.all([
        this.cx.dash.loadCamps(
          this.state.start.format('x'),
          this.state.end.format('x')
        ),
        this.cx.dash.loadCosts(
          this.state.start.format('x'),
          this.state.end.format('x')
        )
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    
    const campsCombined = camps.concat(
      costs
        .filter(c => c[3] === 'active')
        .map(c => {
          return [
            c[0],
            c[1],
            true,
            0,
            0,
            0,
            c[2],
            c[4],
            c[5],
            c[6],
            c[7],
            0,
            0,
            c[8]
          ]
        })
    )
    // filter totals by user subids
    const campTotals = camps.filter(camp =>
      __.getJsonSto('core').countTotals.includes(camp[13])
    )
    const campsCombinedTotals = camps.filter(camp =>
      __.getJsonSto('core').countTotals.includes(camp[13])
    )

    const totalClicks = __.arrSum(campTotals.map(camp => camp[3]))
    const totalRev = __.arrSum(campTotals.map(camp => camp[4]))
    const totalCost = __.arrSum(campsCombinedTotals.map(camp => camp[6]))
    const totalMargin = __.arrSum(campsCombinedTotals.map(camp => camp[11]))
    const todayClicks = __.arrSum(
      campTotals.map(camp => (mo(camp[0]).isSame(mo(), 'day') ? camp[3] : 0))
    )
    const todayRev = __.arrSum(
      campTotals.map(camp => (mo(camp[0]).isSame(mo(), 'day') ? camp[4] : 0))
    )
    const todayCost = __.arrSum(
      campsCombinedTotals.map(camp =>
        mo(camp[0]).isSame(mo(), 'day') ? camp[6] : 0
      )
    )

    const todayMargin = __.arrSum(
      campsCombinedTotals.map(camp =>
        mo(camp[0]).isSame(mo(), 'day') ? camp[11] : 0
      )
    )
    const yesterdayClicks = __.arrSum(
      campTotals.map(camp =>
        mo(camp[0]).isSame(mo().subtract(1, 'day'), 'day') ? camp[3] : 0
      )
    )
    const yesterdayRev = __.arrSum(
      campTotals.map(camp =>
        mo(camp[0]).isSame(mo().subtract(1, 'day'), 'day') ? camp[4] : 0
      )
    )
    const yesterdayCost = __.arrSum(
      campsCombinedTotals.map(camp =>
        mo(camp[0]).isSame(mo().subtract(1, 'day'), 'day') ? camp[6] : 0
      )
    )
    const yesterdayMargin = __.arrSum(
      campsCombinedTotals.map(camp =>
        mo(camp[0]).isSame(mo().subtract(1, 'day'), 'day') ? camp[11] : 0
      )
    )

    this.setState({
      camps: campsCombined,
      refresh: false,
      totalClicks,
      totalRev,
      totalRpc: totalRev / totalClicks,
      totalCost,
      todayClicks,
      todayCost,
      todayRev,
      todayRpc: todayRev / todayClicks,
      yesterdayClicks,
      yesterdayRev,
      yesterdayCost,
      yesterdayRpc: yesterdayRev / yesterdayClicks,
      totalMargin,
      todayMargin,
      yesterdayMargin
    })
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.camps) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar title noInsta={!this.state.isCrawlAllowed} showAlerts />
          <Paper>
            <div style={{ float: 'right' }}>
              <Button
                variant='contained'
                size='small'
                onClick={async evt => {
                  await this.setState({
                    start: mo()
                      .utc()
                      .startOf('day')
                      .subtract(2, 'days'),
                    end: mo()
                      .utc()
                      .endOf('day')
                  })
                  await this.load()
                }}
              >
                Last 3 days
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={async evt => {
                  await this.setState({
                    start: mo()
                      .utc()
                      .startOf('day')
                      .subtract(4, 'days'),
                    end: mo()
                      .utc()
                      .endOf('day')
                  })
                  await this.load()
                }}
              >
                Last 5 days
              </Button>
              <TextField
                id='start'
                label='Start'
                type='date'
                defaultValue={this.state.start.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newStart = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .startOf('day')
                  if (newStart.isSameOrBefore(this.state.end, 'day')) {
                    this.setState({ start: newStart, refresh: true })
                  } else {
                    evt.target.value = this.state.start.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <TextField
                id='end'
                label='End'
                type='date'
                defaultValue={this.state.end.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newEnd = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .endOf('day')
                  if (newEnd.isSameOrAfter(this.state.start, 'day')) {
                    this.setState({ end: newEnd, refresh: true })
                  } else {
                    evt.target.value = this.state.end.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <Button
                variant='contained'
                color='primary'
                disabled={!this.state.refresh}
                onClick={async evt => {
                  await this.load()
                }}
              >
                Refresh
              </Button>
            </div>
            <MUIDataTable
              title='Campaign Data'
              data={this.state.camps}
              columns={[
                {
                  name: 'Date',
                  options: {
                    filterType: 'dropdown',
                    customBodyRender: value => {
                      return mo(value).format(__.cfg('tsYearFormat'))
                    },
                    sortDirection: 'desc'
                  }
                },
                {
                  name: 'Subid1',
                  options: {
                    filterType: 'dropdown',
                    customBodyRender: value => <nobr>{value}</nobr>
                  }
                },
                {
                  name: 'Active',
                  options: {
                    filterType: 'dropdown',
                    display: false,
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    },
                    filterList: ['true'],
                    customBodyRender: value => {
                      return value.toString()
                    }
                  }
                },
                {
                  name: 'Clicks',
                  options: {
                    filterType: 'textField'
                  }
                },
                {
                  name: 'Revenue',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return <nobr>{__.formatNumber(value)} €</nobr>
                    }
                  }
                },
                {
                  name: 'RPC',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return <nobr>{__.formatNumber(value)} €</nobr>
                    }
                  }
                },
                {
                  name: 'Cost',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return <nobr>{__.formatNumber(value)} €</nobr>
                    }
                  }
                },
                {
                  name: 'Margin -48h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterMargin
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} €</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: '% -48h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterPercent
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} %</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'Margin -24h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterMargin
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} €</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: '% -24h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterPercent
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} %</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'Margin',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterMargin
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} €</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: '%',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterPercent
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={<nobr>{__.formatNumber(value)} %</nobr>}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'User Subid',
                  options: {
                    filterType: 'dropdown',
                    display: false,
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  }
                }/*,
                {
                  name: 'tonic view',
                  options: {
                    filterType: 'textField'
                  }
                },
                {
                  name: 'tonic term view',
                  options: {
                    filterType: 'textField'
                  }
                },
                {
                  name: 'tonic ad click',
                  options: {
                    filterType: 'textField'
                  }
                }*/
              ]}
              options={{
                filterType: 'checkbox',
                selectableRows: 'none',
                print: false,
                rowsPerPage: 100,
                rowsPerPageOptions: [10, 30, 100],
                elevation: 0
              }}
            />
            <Paper style={{ padding: 24 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant='h6' color='inherit'>
                    Totals
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Clicks: {this.state.totalClicks}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Revenue: {__.formatNumber(this.state.totalRev)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Cost: {__.formatNumber(this.state.totalCost)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    RPC: {__.formatNumber(this.state.totalRpc)}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Margin: {__.formatNumber(this.state.totalMargin)} €
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h6' color='inherit'>
                    Yesterday
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Clicks: {this.state.yesterdayClicks}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Revenue: {__.formatNumber(this.state.yesterdayRev)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Cost: {__.formatNumber(this.state.yesterdayCost)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    RPC: {__.formatNumber(this.state.yesterdayRpc)}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Margin: {__.formatNumber(this.state.yesterdayMargin)} €
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h6' color='inherit'>
                    Today
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Clicks: {this.state.todayClicks}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Revenue: {__.formatNumber(this.state.todayRev)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Cost: {__.formatNumber(this.state.todayCost)} €
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    RPC: {__.formatNumber(this.state.todayRpc)}
                  </Typography>
                  <Typography variant='body1' color='inherit'>
                    Margin: {__.formatNumber(this.state.todayMargin)} €
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </div>
      )
    } else {
      return <LinearProgress />
    }
  }
}

export default compose(
  withStyles({
    colorFormat
  })
)(DashView)
