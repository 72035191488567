import { StoBase } from './Lib'
import User from './User'
import Dash from './Dash'
// import Job from './Job'
import __ from '../util'

export default class Core extends StoBase {
  constructor (cx) {
    super('core', cx, 'e7211674-6119-47c1-9c3d-a878f13b919f')
    this._store = 'core'
    this.isActive = () => Boolean(this.getSto())
    this.clear = this.clear.bind(this)
    this.init = this.init.bind(this)
    this.get = this.get.bind(this)
  }

  clear () {
    const cx = this.cx
    __.clearObj(cx)
    cx.core = new Core(cx)
    __.clearSto()
  }

  get (key) {
    const core = this.getSto() || {}
    const val = core[key]
    if (val === undefined) {
      this.clear()
      throw this.err(`Getting core data "${key}" failed`, { sts: 900 })
    }
    return val
  }

  init (email, token, countTotals) {
    if (token) {
      //const user = email.match(/^(\w+)@.+/)
      this.setSto({
        token,
        countTotals: countTotals
      })
    }

    const core = this.getSto()

    if (!core) return false

    if (!this.cx.user) {
      Object.assign(this.cx, {
        tmp: {},
        user: new User(this.cx, email)
      })
      Object.assign(this.cx, {
        dash: new Dash(this.cx, token || core.token)
        // job: new Job(this.cx, token || core.token)
      })
    }
    return true
  }

  async login (email, password) {
    this.clear()
    let pld
    try {
      pld = await this.rqst({
        url: 'login',
        method: 'post',
        data: { email, password }
      })
      this.init(email, pld.token, pld.countTotals)
    } catch (e) {
      let emsg, sts
      if (e.sts === 404) {
        emsg = 'Invalid login/password combination'
        sts = 404
      } else if (e.sts >= 400 && e.sts < 500) {
        emsg = 'Invalid login/password combination'
        sts = 400
      } else {
        emsg = 'Login failed temporary: Please try again later'
        sts = e.sts
      }
      throw this.err(emsg, { e, sts })
    }
  }
}
