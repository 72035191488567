const d = {
  isDev: process.env.NODE_ENV === 'development'
}

Object.assign(d, {
  apiUrl: 'https://api.fbkeyword.services/v1',
  homeUrl: 'https://fbkeyword.services',
  cdn: 'https://cdn.fbkeyword.services',
  lstMax: 50,
  maxName: 35,
  maxLow: 25,
  maxElip: 5,
  maxHigh: 100,
  minPw: 8,
  maxPw: 36,
  tsYearFormat: 'YYYY-MM-DD',
  tsDateFormat: 'YYYY-MM-DD HH:mm',
  tsDateFormatDetail: 'YYYY-MM-DD HH:mm:ss',
  tsFormat: 'HH:mm',
  tsFormatDetail: 'HH:mm:ss',
  dfltLocale: 'DE'
})

const cfg = key => (key == null ? d : d[key])
export default cfg
