import React from 'react'
import compose from 'recompose/compose'
import { ArrowBack } from '@material-ui/icons'
import * as mo from 'moment'
import {
  TextField,
  Paper,
  Button,
  LinearProgress,
  withStyles
} from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import {
  TopBar,
  Snack,
  ModalCust,
  TableColorFormaterMargin,
  TableColorFormaterPercent
} from './Lib'
import { styleGuide, paperStyle, colorFormat } from './Style'
import __ from '../util'

class CostsView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      start: mo()
        .utc()
        .startOf('day')
        .subtract(2, 'days'),
      end: mo()
        .utc()
        .endOf('day'),
      busy: false
    }
    this.state = {
      ...this.reset
    }
    this.load = this.load.bind(this)
    this.handleGoBack = () => this.props.history.push('/dash')
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'costs'))
    await this.load()
  }

  async load () {
    let costs
    this.setState({
      busy: true
    })
    try {
      ;[costs] = await Promise.all([
        this.cx.dash.loadCosts(
          this.state.start.format('x'),
          this.state.end.format('x')
        )
      ])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.setState({
      costs,
      busy: false
    })
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (!this.state.busy) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noUpdate
            noCosts
            iconLeft={<ArrowBack />}
            midTitle='FB Costs without Click Data'
            onClickLeft={this.handleGoBack}
          />
          <Paper style={{ width: '80%', margin: '0 auto', marginTop: 30 }}>
            <div style={{ float: 'right' }}>
              <TextField
                id='start'
                label='Start'
                type='date'
                defaultValue={this.state.start.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newStart = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .startOf('day')
                  if (newStart.isSameOrBefore(this.state.end, 'day')) {
                    this.setState({ start: newStart, refresh: true })
                  } else {
                    evt.target.value = this.state.start.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <TextField
                id='end'
                label='End'
                type='date'
                defaultValue={this.state.end.format(__.cfg('tsYearFormat'))}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={evt => {
                  const newEnd = mo
                    .utc(evt.target.value, 'YYYY-MM-DD')
                    .endOf('day')
                  if (newEnd.isSameOrAfter(this.state.start, 'day')) {
                    this.setState({ end: newEnd, refresh: true })
                  } else {
                    evt.target.value = this.state.end.format(
                      __.cfg('tsYearFormat')
                    )
                    return false
                  }
                }}
              />
              <Button
                variant='contained'
                color='primary'
                disabled={!this.state.refresh}
                onClick={async evt => {
                  await this.load()
                }}
              >
                Refresh
              </Button>
            </div>
            <MUIDataTable
              title='FB Costs Data'
              data={this.state.costs}
              columns={[
                {
                  name: 'Date',
                  options: {
                    filterType: 'dropdown'
                  }
                },
                {
                  name: 'Subid1',
                  options: {
                    filterType: 'dropdown'
                  }
                },
                {
                  name: 'Cost',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return `${__.formatNumber(value)} €`
                    }
                  }
                },
                {
                  name: 'FB Status',
                  options: {
                    filterType: 'dropdown'
                  }
                },
                {
                  name: 'Margin -48h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterMargin
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={`${__.formatNumber(value)} €`}
                        />
                      )
                    }
                  }
                },
                {
                  name: '% -48h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterPercent
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={`${__.formatNumber(value)} %`}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'Margin -24h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterMargin
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={`${__.formatNumber(value)} €`}
                        />
                      )
                    }
                  }
                },
                {
                  name: '% -24h',
                  options: {
                    filterType: 'textField',
                    customBodyRender: value => {
                      return (
                        <TableColorFormaterPercent
                          className={this.props.classes.colorFormat}
                          colorValue={value}
                          text={`${__.formatNumber(value)} %`}
                        />
                      )
                    }
                  }
                },
                {
                  name: 'User Subid',
                  options: {
                    filterType: 'dropdown',
                    display: false,
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  }
                }
              ]}
              options={{
                filterType: 'checkbox',
                selectableRows: 'none',
                print: false,
                rowsPerPage: 100,
                rowsPerPageOptions: [10, 30, 100],
                elevation: 0
              }}
            />
          </Paper>
        </div>
      )
    } else {
      return <LinearProgress />
    }
  }
}

export default compose(
  withStyles({
    paperStyle,
    colorFormat
  })
)(CostsView)
