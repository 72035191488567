import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { ModalCust } from './Lib'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  paperStyle,
  loginStyle,
  fullWidth,
  styleGuide,
  loginButton,
  lockIcon
} from './Style'
import __ from '../util'

class LoginView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.reset = {
      loginBusy: false,
      upd: false,
      email: '',
      password: '',
      err: undefined
    }
    this.state = { ...this.reset }
    this.goBack = props.history.goBack
    this.login = this.login.bind(this)
    this.set = this.set.bind(this)
    this.reload = () => this.setState(this.reset)
  }

  componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark
    Object.assign(this, __.initView(this, 'login'))
  }

  set (ilk, val) {
    this.setState({ [ilk]: val, emsg: undefined }, () => {
      const d = { upd: false }

      if (this.state.password) {
        d.emailEmsg = __.vldEmail(this.state.email)
        d.passwordEmsg = __.vldPw(this.state.password, true)
      }
      if (
        this.state.email &&
        this.state.password &&
        !d.emailEmsg &&
        !d.passwordEmsg
      ) {
        d.upd = true
      }
      this.setState(d)
    })
  }

  async login () {
    this.setState({ err: undefined, loginBusy: true })
    try {
      await this.cx.core.login(this.state.email, this.state.password)
      this.props.history.push('/dash')
    } catch (e) {
      if (e.sts >= 400 && e.sts < 500) {
        this.setState({
          ...this.reset,
          email: this.state.email,
          password: this.state.password,
          emsg: e.message
        })
      } else {
        if (__.cfg('isDev')) throw e
        return this.setState({ err: e.message })
      }
    }
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          onClose={async () => await this.reload()}
          actions={[{ lbl: 'OK', onClick: async () => await this.reload() }]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else {
      return (
        <div>
          <div className={this.props.classes.loginStyle}>
            <Grid container spacing={0} justify='center'>
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Typography variant='h2' color='inherit' align='center'>
                  <img
                    height='60px'
                    src='/svg/robot.svg'
                    title='Logo fbkeyword.services'
                    alt='Logo fbkeyword.services'
                    style={{ border: '1px solid white', borderRadius: '6px' }}
                  />
                  &nbsp;FB-KW Services
                  <span style={{ fontSize: '14px' }}>[BETA]</span>
                </Typography>
                <Typography
                  variant='h4'
                  align='center'
                  color='inherit'
                  gutterBottom
                >
                  Please enter your credentials
                </Typography>
                {this.state.loginBusy && <LinearProgress />}
                <Paper
                  square
                  className={this.props.classes.paperStyle}
                  elevation={24}
                >
                  <TextField
                    autoFocus
                    fullWidth
                    label='Email'
                    margin='normal'
                    value={this.state.email}
                    error={
                      Boolean(this.state.emsg) || Boolean(this.state.emailEmsg)
                    }
                    helperText={this.state.emsg || this.state.emailEmsg}
                    onChange={evt => this.set('email', evt.target.value)}
                  />
                  <TextField
                    fullWidth
                    label='Password'
                    type='password'
                    margin='normal'
                    autoComplete='password'
                    value={this.state.password}
                    error={
                      Boolean(this.state.emsg) ||
                      Boolean(this.state.passwordEmsg)
                    }
                    helperText={this.state.emsg || this.state.passwordEmsg}
                    onChange={evt => this.set('password', evt.target.value)}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    className={this.props.classes.loginButton}
                    onClick={async event => this.login(event)}
                    disabled={!this.state.upd || this.state.loginBusy}
                  >
                    Login
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      )
    }
  }
}

export default withStyles({
  loginStyle,
  paperStyle,
  fullWidth,
  loginButton,
  lockIcon
})(LoginView)
