import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './view/Style'
import Core from './logic/Core'
import DashView from './view/Dash'
import StatsView from './view/Stats'
import CostsView from './view/Costs'
import KeywordView from './view/Keyword'
import UploadView from './view/Upload'
import UserView from './view/User'
import LoginView from './view/Login'
// import __ from './util'

const cx = {}
cx.core = new Core(cx)

const login = props => {
  props.cx = cx
  return cx.core.init() ? <Redirect to='/dash' /> : <LoginView {...props} />
}

const AuthRoute = ({ component: Component, ...args }) => (
  <Route
    {...args}
    render={props => {
      props.cx = cx
      return cx.core.init() ? (
        <Component {...props} />
      ) : (
        <Redirect to='/login' />
      )
    }}
  />
)

const Routes = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path='/login' exact render={login} />

        <AuthRoute path='/dash' exact component={DashView} />
        <AuthRoute path='/costs' exact component={CostsView} />
        <AuthRoute path='/keyword' exact component={KeywordView} />
        <AuthRoute path='/upload' exact component={UploadView} />
        <AuthRoute path='/stats' exact component={StatsView} />
        <AuthRoute path='/user' exact component={UserView} />

        <Redirect to='/dash' />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
)

ReactDOM.render(<Routes />, document.getElementById('root'))
registerServiceWorker()
