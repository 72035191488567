import React from 'react'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Modal from '@material-ui/core/Modal'
import InputLabel from '@material-ui/core/InputLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import Button from '@material-ui/core/Button'
import * as mo from 'moment'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  Close,
  AccountCircle,
  OpenInNew,
  CheckCircle,
  Traffic
} from '@material-ui/icons'
import LoopIcon from '@material-ui/icons/Loop'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PaymentIcon from '@material-ui/icons/Payment'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Group } from '@vx/group'
import { BarGroup } from '@vx/shape'
import { AxisBottom } from '@vx/axis'
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale'
import { withTooltip, Tooltip } from '@vx/tooltip'
import { ParentSize } from '@vx/responsive'
import { theme } from './Style'
import __ from '../util'

const TopBar = ({
  title,
  midTitle,
  action,
  onClick,
  iconLeft,
  onClickLeft,
  color,
  noUser,
  noUpload,
  noUpdate,
  noCosts,
  noKeyword,
  noStats,
  modeCancel,
  isActionAllowed = true
}) => (
  <AppBar
    position='static'
    color={color || 'default'}
    elevation={0}
    style={{ zIndex: 1200 }}
  >
    <Toolbar style={{ minHeight: '50px' }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 'auto' }}>
          {modeCancel && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClickLeft}
              style={{
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              Cancel
            </Typography>
          )}
          {iconLeft && (
            <IconButton
              aria-label='Menu'
              onClick={onClickLeft}
              color='inherit'
              style={{
                right: theme.spacing(2),
                marginRight: 'auto'
              }}
            >
              {iconLeft}
            </IconButton>
          )}
          {title && (
            <Typography
              variant='h5'
              color='inherit'
              style={{ lineHeight: '27px' }}
            >
              <Hidden xsDown>
                <img
                  height='20px'
                  src='/svg/robot.svg'
                  title='Logo fbkeyword.services'
                  alt='Logo fbkeyword.services'
                  style={{
                    float: 'left',
                    border: '1px solid white',
                    borderRadius: '6px'
                  }}
                />
                &nbsp;
                <span>Fbkeyword.services</span>
              </Hidden>
              <Hidden smUp>
                <span>Fbkeyword.services</span>
              </Hidden>
            </Typography>
          )}
        </div>
      </div>
      <div>
        <Typography
          variant='h5'
          color='inherit'
          align='center'
          style={{
            flex: 1,
            fontSize: '16px',
            fontWeight: '700'
          }}
        >
          {midTitle || ''}
        </Typography>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginLeft: 'auto' }}>
          {action && (
            <Typography
              variant='h5'
              color='inherit'
              onClick={onClick}
              style={{
                fontSize: '16px',
                cursor: isActionAllowed ? 'pointer' : 'not-allowed',
                opacity: isActionAllowed ? 1 : 0.5
              }}
            >
              {action}
            </Typography>
          )}
          {!noUpdate && (
            <Link to='/update' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <LoopIcon />
              </IconButton>
            </Link>
          )}
          {!noCosts && (
            <Link to='/costs' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <PaymentIcon />
              </IconButton>
            </Link>
          )}
          {!noUpload && (
            <Link to='/upload' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Link>
          )}
          {!noStats && (
            <Link to='/stats' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <ShowChartIcon />
              </IconButton>
            </Link>
          )}
          {!noKeyword && (
            <Link to='/keyword' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <SpellcheckIcon />
              </IconButton>
            </Link>
          )}
          {!noUser && (
            <Link to='/user' style={{ color: 'white' }}>
              <IconButton
                aria-label='Menu'
                color='inherit'
                style={{
                  left: theme.spacing(2)
                }}
              >
                <AccountCircle />
              </IconButton>
            </Link>
          )}
        </div>
      </div>
    </Toolbar>
  </AppBar>
)

const Snack = ({ msg, onClose }) => (
  <Snackbar
    open
    autoHideDuration={4000}
    transitionDuration={800}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={onClose}
    ContentProps={{ 'aria-describedby': 'message-id' }}
    message={<span id='message-id'>{msg}</span>}
    action={[
      <IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        onClick={onClose}
      >
        <Close />
      </IconButton>
    ]}
  />
)

const ExtLink = ({ to, txt, className, style }) => (
  <a
    href={to}
    target='_blank'
    rel='noopener noreferrer'
    className={className}
    style={style}
  >
    {txt}
  </a>
)

const TableColorFormaterPercent = ({ className, colorValue, text }) => {
  let cl = 'red'
  if (colorValue > 70) {
    cl = '#9CC17E'
  } else if (colorValue > 50) {
    cl = '#BDD7A9'
  } else if (colorValue > 20) {
    cl = '#A8C3F1'
  } else if (colorValue > 0.1) {
    cl = '#faedca'
  } else {
    cl = '#EBCFCC'
  }
  return (
    <span className={className} style={{ background: cl }}>
      {text}
    </span>
  )
}

const TableColorFormaterMargin = ({ className, colorValue, text }) => {
  return (
    <span
      className={className}
      style={{ background: __.colorValTable(colorValue) }}
    >
      {text}
    </span>
  )
}

const ExtBtn = () => <OpenInNew color='action' style={{ fontSize: 18 }} />

const CheckBtn = () => <CheckCircle color='action' style={{ fontSize: 18 }} />

const TrafficIcon = ({ color }) => (
  <Traffic style={{ fontSize: 25, color: color }} />
)

const AlertDescription = ({ al }) => (
  <Typography component='p' gutterBottom>
    {al.alert && al.alert === 'statusCode' && (
      <span>Http Status Code {al.statusCode}</span>
    )}
    {al.alert && al.alert === 'timeout' && <span>Http Status Code 418</span>}
    {al.alert && al.alert === 'responseTime' && (
      <span>
        Response Time Percent Change +{' '}
        {parseFloat(al.loadTimePcChange).toFixed(2)}%
      </span>
    )}
    {al.alert && al.alert === 'redirectChain' && (
      <span>
        Redirects changed from {al.lastRedirectChain || 0} to {al.redirectChain}
      </span>
    )}
    {al.alert && al.alert === 'cookieCount' && (
      <span>
        Cookies changed from {al.lastCookieCount || 0} to {al.cookieCount}
      </span>
    )}
    {al.alert && al.alert === 'pageSize' && (
      <span>
        Page size changed from {al.lastPageSize || 0} to {al.pageSize}
      </span>
    )}
    {al.alert && al.alert === 'metaTitle' && (
      <span>
        Meta Title changed
        {al.metaTitleDiff}
      </span>
    )}
    {al.alert && al.alert === 'metaDesc' && (
      <span>
        Meta Description changed
        {al.metaDescDiff}
      </span>
    )}
    {al.alert && al.alert === 'metaRobots' && (
      <span>
        Meta Robots changed
        {al.metaRobotsDiff}
      </span>
    )}
    {al.alert && al.alert === 'h1First' && (
      <span>
        First H1 changed from "{al.lastH1First || '-'}" to "{al.h1First}"
      </span>
    )}
    {al.alert && al.alert === 'h2First' && (
      <span>
        First H2 changed from "{al.lastH2First || '-'}" to "{al.h2First}"
      </span>
    )}
    {al.alert && al.alert === 'h1Count' && (
      <span>
        Headline (H1) count changed from {al.lastH1Count || 0} to {al.h1Count}
      </span>
    )}
    {al.alert && al.alert === 'imgCount' && (
      <span>
        Image count changed from {al.lastImgCount || 0} to {al.imgCount}
      </span>
    )}
    {al.alert && al.alert === 'jsFiles' && (
      <span>
        JS file count changed from {al.lastJsFiles.length || 0} to
        {al.jsFiles.length}
      </span>
    )}
    {al.alert && al.alert === 'jsSize' && (
      <span>
        JS size changed from {al.lastJsSize || 0} to {al.jsSize}
      </span>
    )}
    {al.alert && al.alert === 'adobeTrackingAccount' && (
      <span>
        AdobeTracking changed from "{al.lastAdobeTrackingAccount || '-'}" to "
        {al.adobeTrackingAccount}"
      </span>
    )}
    {al.alert && al.alert === 'encoding' && (
      <span>
        Encoding changed from "{al.lastEncoding || '-'}" to "{al.encoding}"
      </span>
    )}
    {al.alert && al.alert === 'charset' && (
      <span>
        Charset changed from "{al.lastCharset || '-'}" to "{al.charset}"
      </span>
    )}
    {al.alert && al.alert === 'canonical' && (
      <span>
        Canonical changed from "{al.lastCanonical || '-'}" to "{al.canonical}"
      </span>
    )}
  </Typography>
)

class ModalCust extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.children = props.children
    this.open = props.open == null ? true : props.open
    this.onClose = props.onClose
    this.lbl = props.lbl || 'Error'
    this.fullScreen = props.fullScreen || false
    this.simple = props.simple || false
    const acs = props.actions || [{ lbl: 'OK', onClick: this.onClose }]
    if (!props.noCncl && this.lbl.toLowerCase() !== 'error') {
      acs.push({ lbl: 'Cancel', onClick: this.onClose })
    }
    this.btns = []
    for (const ac of acs) {
      this.btns.push(
        <Button
          key={ac.key || __.uuid()}
          onClick={
            props.withBusy
              ? async (...args) => {
                this.setState({ busy: true })
                const d = await ac.onClick(...args)
                return d
              }
              : ac.onClick
          }
        >
          {ac.lbl}
        </Button>
      )
    }
  }

  render () {
    if (this.fullScreen) {
      return (
        <Dialog
          fullScreen
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                color='inherit'
                onClick={this.onClose}
                aria-label='Close'
              >
                <Close />
              </IconButton>
              <Typography variant='h6' color='inherit' style={{ flex: 1 }}>
                {this.lbl}
              </Typography>
            </Toolbar>
          </AppBar>
          {this.children}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else if (this.simple) {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>{this.children}</DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    } else {
      return (
        <Dialog
          open={this.open}
          onClose={this.onClose}
          style={{ background: theme.palette.background.default }}
        >
          <DialogTitle>{this.lbl}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.children}</DialogContentText>
          </DialogContent>
          {!this.state.busy && this.btns && (
            <DialogActions>{this.btns}</DialogActions>
          )}
          {this.state.busy && <LinearProgress />}
        </Dialog>
      )
    }
  }
}

// TODO umbauen auf POPOVER https://material-ui.com/components/popover/
const ModalOver = ({ children, open = false, handleOnClose }) => (
  <Modal open={open} onClose={handleOnClose}>
    <div
      style={{
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        outline: 'none',
        overflow: 'auto',
        maxHeight: '90%'
      }}
    >
      {children}
    </div>
  </Modal>
)

class DropDown extends React.Component {
  // usage:
  //   <DropDown
  //     _id=<unique_id>
  //     data={this.state.<array_with_objs>}
  //     slctd={this.state.<label_of_selected_item}
  //     action={this.<action_function>}
  //     disabled={this.state.<disabled>}
  //     error={this.state.<inputError>}
  //     errorMsg={this.state.<inputErrorMsg>}
  //   />
  constructor (props) {
    super(props)
    this._id = props._id
    this.data = props.data
    this.title = props.title
    this.action = props.action
    this.renderValue = props.renderValue || (value => value)
    this.state = {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
    this.handleChange = name => event => {
      this.setState({ [name]: event.target.value })
      this.action(
        this.data.find(i => {
          return i.lbl === event.target.value
        })
      )
    }
  }

  static getDerivedStateFromProps (props, state) {
    return {
      slctd: props.slctd,
      disabled: props.disabled,
      error: props.error,
      errorMsg: props.errorMsg
    }
  }

  render () {
    return (
      <FormControl fullWidth margin='normal'>
        <InputLabel htmlFor={this._id}>{this.title}</InputLabel>
        <Select
          value={this.state.slctd}
          onChange={this.handleChange('slctd')}
          input={<Input id={this._id} />}
          disabled={this.state.disabled}
          renderValue={this.renderValue}
          error={this.state.error}
        >
          {this.data.map(d => (
            <MenuItem key={d.key} value={d.lbl}>
              {d.lbl}
            </MenuItem>
          ))}
        </Select>
        {this.state.error && this.state.errorMsg && (
          <FormHelperText>{this.state.errorMsg}</FormHelperText>
        )}
      </FormControl>
    )
  }
}

const GraphDaily = withTooltip(
  ({
    data,
    margin = { top: 60 },
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  }) => {
    const keys = Object.keys(data[0]).filter(d => d !== 'date')
    // accessors
    const x0 = d => d.date

    // scales
    const x0Scale = scaleBand({
      domain: data.map(x0),
      padding: 0.2
    })
    const x1Scale = scaleBand({
      domain: keys,
      padding: 0.1
    })
    const yScale = scaleLinear({
      domain: [
        0,
        Math.max(...data.map(d => Math.max(...keys.map(key => d[key]))))
      ]
    })
    const color = scaleOrdinal({
      domain: keys,
      range: ['#575757', '#9caff6', '#e5fd3d']
    })

    return (
      <ParentSize>
        {({ width: w, height: h }) => {
          const heightManual = 400
          const xMax = w
          const yMax = heightManual - margin.top - 100
          x0Scale.rangeRound([0, xMax])
          x1Scale.rangeRound([0, x0Scale.bandwidth()])
          yScale.range([yMax, 0])
          let tooltipTimeout
          return (
            <div>
              <svg width={w} height={heightManual}>
                <rect
                  x={0}
                  y={0}
                  width={w}
                  height={heightManual}
                  fill='#fff'
                  rx={14}
                />
                <Group top={margin.top}>
                  <BarGroup
                    data={data}
                    keys={keys}
                    height={yMax}
                    x0={x0}
                    x0Scale={x0Scale}
                    x1Scale={x1Scale}
                    yScale={yScale}
                    color={color}
                  >
                    {barGroups => {
                      return barGroups.map(barGroup => {
                        return (
                          <Group
                            key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                            left={barGroup.x0}
                          >
                            {barGroup.bars.map(bar => {
                              return (
                                <rect
                                  key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                                  x={bar.x}
                                  y={bar.y}
                                  width={bar.width}
                                  height={bar.height}
                                  fill={bar.color}
                                  rx={4}
                                  onMouseLeave={event => {
                                    tooltipTimeout = setTimeout(() => {
                                      hideTooltip()
                                    }, 300)
                                  }}
                                  onMouseMove={event => {
                                    if (tooltipTimeout) {
                                      clearTimeout(tooltipTimeout)
                                    }
                                    showTooltip({
                                      tooltipData: bar,
                                      tooltipTop: event.clientY - margin.top,
                                      tooltipLeft: event.clientX - bar.width
                                    })
                                  }}
                                />
                              )
                            })}
                          </Group>
                        )
                      })
                    }}
                  </BarGroup>
                </Group>
                <AxisBottom
                  top={yMax + margin.top}
                  tickFormat={date => mo(date, 'YYYYMMDD').format('MMM DD')}
                  scale={x0Scale}
                  stroke='black'
                  tickStroke='black'
                  hideAxisLine
                  tickLabelProps={(value, index) => ({
                    fill: 'black',
                    fontSize: 14,
                    textAnchor: 'middle',
                    fontFamily: 'Roboto'
                  })}
                />
              </svg>
              {tooltipOpen && (
                <Tooltip
                  top={tooltipTop}
                  left={tooltipLeft}
                  style={{
                    minWidth: 60,
                    backgroundColor: '#575757',
                    color: 'white'
                  }}
                >
                  <Typography variant='h6' noWrap>
                    {tooltipData.key}
                  </Typography>
                  <Typography variant='h6' noWrap>
                    {tooltipData.key === 'Clicks'
                      ? tooltipData.value
                      : `${__.formatNumber(tooltipData.value)} €`}
                  </Typography>
                </Tooltip>
              )}
            </div>
          )
        }}
      </ParentSize>
    )
  }
)

export {
  TopBar,
  Snack,
  ModalCust,
  ModalOver,
  DropDown,
  ExtLink,
  ExtBtn,
  CheckBtn,
  TrafficIcon,
  AlertDescription,
  TableColorFormaterPercent,
  TableColorFormaterMargin,
  GraphDaily
}
