/* global FileReader */

// import * as mo from 'moment'
import { StoBase } from './Lib'
import __ from '../util'

export default class Dash extends StoBase {
  constructor (cx, token) {
    super('Dash', cx, 'd0553565-13a9-4f5f-9a02-5a6994533883')
    this.token = token
    this.getFilterSto = page => __.getJsonSto(`filter_${page}`)
    this.setFilterSto = (page, filterList) =>
      __.setJsonSto(`filter_${page}`, filterList)
    this.getBxpSto = () => __.getSto('bxp')
    this.setBxpSto = val => __.setSto('bxp', val)
    this.loadCamps = this.loadCamps.bind(this)
    this.uploadCsv = this.uploadCsv.bind(this)
    this.loadCosts = this.loadCosts.bind(this)
  }

  async loadCamps (start, end) {
    const pld = await this.rqst(
      {
        url: 'camps',
        params: {
          start,
          end
        }
      },
      this.token
    )
    return pld
  }

  async uploadCsv (files) {
    const rqst = this.rqst
    const token = this.token
    // for (var file of files) {
    const file = files[0]
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = async function () {
      const pld = await rqst(
        {
          url: 'upload',
          method: 'post',
          data: { name: file.name, content: reader.result }
        },
        token
      )
      return pld
    }
    // }
  }

  async loadCosts (start, end) {
    const pld = await this.rqst(
      {
        url: 'costs',
        params: {
          start,
          end
        }
      },
      this.token
    )
    return pld
  }

  async loadKeyword (start, end) {
    const pld = await this.rqst(
      {
        url: 'keyword',
        params: {
          start,
          end
        }
      },
      this.token
    )
    return pld
  }

  async loadStatsDaily (start, end) {
    const pld = await this.rqst(
      {
        url: 'stats/daily',
        params: {
          start,
          end
        }
      },
      this.token
    )
    return pld
  }
}
