import React from 'react'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'
import { DropzoneArea } from 'material-ui-dropzone'
import { ArrowBack } from '@material-ui/icons'
import { Paper, Button, LinearProgress, withStyles } from '@material-ui/core'
import { TopBar, Snack, ModalCust } from './Lib'
import {
  theme,
  styleGuide,
  gridWrapPaper,
  gridItem,
  gridSpacer,
  paperStyle
} from './Style'
import __ from '../util'

class UploadView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = { busy: false, files: [] }
    this.state = {
      ...this.reset
    }
    this.handleGoBack = () => this.props.history.push('/dash')
    this.handleChange = this.handleChange.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }

  handleChange (files) {
    this.setState({
      files: files
    })
  }

  async handleUpload () {
    this.setState({
      busy: true
    })

    try {
      await Promise.all([this.cx.dash.uploadCsv(this.state.files)])
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
    this.setState({
      ...this.reset
    })
  }

  async componentDidMount () {
    // set body bg
    document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'upload'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (!this.state.busy) {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noUpdate
            noUpload
            iconLeft={<ArrowBack />}
            midTitle='FB CSV Upload'
            onClickLeft={this.handleGoBack}
          />
          <Paper style={{ width: '80%', margin: '0 auto', marginTop: 30 }}>
            <DropzoneArea
              acceptedFiles={['text/csv']}
              filesLimit={1}
              showFileNames
              dropzoneText='Drag and drop the CSV file here or click'
              onChange={this.handleChange.bind(this)}
            />
            <Button
              style={{ width: '100%' }}
              onClick={this.handleUpload}
              disabled={this.state.files.length === 0}
            >
              Upload
            </Button>
          </Paper>
        </div>
      )
    } else {
      return <LinearProgress />
    }
  }
}

export default compose(
  withStyles({
    gridWrapPaper,
    gridItem,
    gridSpacer,
    paperStyle,
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: 150
    },
    menuItem: {
      width: 120
    }
  }),
  withWidth()
)(UploadView)
